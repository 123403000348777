// Generated by Melange

import * as Caml from "melange.runtime/caml.bs.js";
import * as Caml_format from "melange.runtime/caml_format.bs.js";

function abs(x) {
  if (x >= 0) {
    return x;
  } else {
    return -x | 0;
  }
}

var min_int = -2147483648;

function lognot(x) {
  return x ^ -1;
}

function equal(prim0, prim1) {
  return prim0 === prim1;
}

var compare = Caml.caml_int_compare;

function min(x, y) {
  if (x <= y) {
    return x;
  } else {
    return y;
  }
}

function max(x, y) {
  if (x >= y) {
    return x;
  } else {
    return y;
  }
}

function to_string(x) {
  return Caml_format.caml_format_int("%d", x);
}

var zero = 0;

var one = 1;

var minus_one = -1;

var max_int = 2147483647;

export {
  zero ,
  one ,
  minus_one ,
  abs ,
  max_int ,
  min_int ,
  lognot ,
  equal ,
  compare ,
  min ,
  max ,
  to_string ,
}
/* No side effect */
