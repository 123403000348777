// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Js_exn from "melange.belt/./js_exn.bs.js";
import * as Stdlib from "melange/./stdlib.bs.js";
import * as Js_dict from "melange.belt/./js_dict.bs.js";
import * as Js_json from "melange.belt/./js_json.bs.js";
import * as Belt_List from "melange.belt/./belt_List.bs.js";
import * as Belt_Option from "melange.belt/./belt_Option.bs.js";
import * as Stdlib__List from "melange/stdlib_modules/list.bs.js";
import * as Stdlib__Array from "melange/stdlib_modules/array.bs.js";
import * as Stdlib__Format from "melange/stdlib_modules/format.bs.js";
import * as Caml_js_exceptions from "melange.runtime/caml_js_exceptions.bs.js";
import * as Decoders_bs__Decode from "./decode.bs.js";
import * as Decoders_bs__Encode from "./encode.bs.js";

function pp(fmt, json) {
  Curry._1(Stdlib__Format.fprintf(fmt)(/* Format */{
            _0: {
              TAG: /* Formatting_gen */18,
              _0: {
                TAG: /* Open_box */1,
                _0: /* Format */{
                  _0: /* End_of_format */0,
                  _1: ""
                }
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: /* Close_box */0,
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "@[%s@]"
          }), JSON.stringify(json, null, 2));
}

function of_string(string) {
  try {
    return {
            TAG: /* Ok */0,
            _0: JSON.parse(string)
          };
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Js_exn.$$Error) {
      return {
              TAG: /* Error */1,
              _0: Belt_Option.getWithDefault(e._1.message, "unknown")
            };
    }
    throw e;
  }
}

function of_file(_file) {
  return Stdlib.failwith("Not implemented");
}

function get_int(json) {
  return Belt_Option.map(Js_json.decodeNumber(json), (function (prim) {
                return prim | 0;
              }));
}

function get_null(value) {
  return Belt_Option.map(Js_json.decodeNull(value), (function (param) {
                
              }));
}

function get_list(value) {
  return Belt_Option.map(Js_json.decodeArray(value), Stdlib__Array.to_list);
}

function get_key_value_pairs(value) {
  return Belt_Option.map(Js_json.decodeObject(value), (function (dict) {
                return Stdlib__List.map((function (param) {
                              return [
                                      param[0],
                                      param[1]
                                    ];
                            }), Stdlib__Array.to_list(Js_dict.entries(dict)));
              }));
}

var to_list = Stdlib__Array.of_list;

var Json_decodeable = {
  pp: pp,
  of_string: of_string,
  of_file: of_file,
  get_string: Js_json.decodeString,
  get_int: get_int,
  get_float: Js_json.decodeNumber,
  get_bool: Js_json.decodeBoolean,
  get_null: get_null,
  get_list: get_list,
  get_key_value_pairs: get_key_value_pairs,
  to_list: to_list
};

var Decode = Decoders_bs__Decode.Make(Json_decodeable);

function to_string(json) {
  return JSON.stringify(json);
}

function of_string$1(x) {
  return x;
}

function of_int(x) {
  return x;
}

function of_float(x) {
  return x;
}

function of_bool(x) {
  return x;
}

var $$null = null;

var of_list = Stdlib__Array.of_list;

function of_key_value_pairs(xs) {
  return Js_dict.fromList(Belt_List.keepMap(xs, (function (param) {
                    var v = param[1];
                    return Belt_Option.map(Js_json.decodeString(param[0]), (function (k) {
                                  return [
                                          k,
                                          v
                                        ];
                                }));
                  })));
}

var Json_encodeable = {
  to_string: to_string,
  of_string: of_string$1,
  of_int: of_int,
  of_float: of_float,
  of_bool: of_bool,
  $$null: $$null,
  of_list: of_list,
  of_key_value_pairs: of_key_value_pairs
};

var Encode = Decoders_bs__Encode.Make(Json_encodeable);

export {
  Decode ,
  Encode ,
}
/* Decode Not a pure module */
