// Generated by Melange

import * as Caml_js_exceptions from "melange.runtime/./caml_js_exceptions.bs.js";

var anyToExnInternal = Caml_js_exceptions.internalToOCamlException;

function raiseError(str) {
  throw new Error(str);
}

function raiseEvalError(str) {
  throw new EvalError(str);
}

function raiseRangeError(str) {
  throw new RangeError(str);
}

function raiseReferenceError(str) {
  throw new ReferenceError(str);
}

function raiseSyntaxError(str) {
  throw new SyntaxError(str);
}

function raiseTypeError(str) {
  throw new TypeError(str);
}

function raiseUriError(str) {
  throw new URIError(str);
}

var $$Error$1 = Caml_js_exceptions.$$Error;

export {
  $$Error$1 as $$Error,
  anyToExnInternal ,
  raiseError ,
  raiseEvalError ,
  raiseRangeError ,
  raiseReferenceError ,
  raiseSyntaxError ,
  raiseTypeError ,
  raiseUriError ,
}
/* No side effect */
