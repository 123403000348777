// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Belt_List from "melange.belt/./belt_List.bs.js";
import * as Belt_Option from "melange.belt/./belt_Option.bs.js";
import * as Belt_Result from "melange.belt/./belt_Result.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";

function $$return(x) {
  return {
          TAG: /* Ok */0,
          _0: x
        };
}

function map(f, x) {
  return Belt_Result.map(x, f);
}

function map_err(f, x) {
  if (x.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: x._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Curry._1(f, x._0)
          };
  }
}

var Infix = {
  $great$pipe$eq: Belt_Result.map,
  $great$great$eq: Belt_Result.flatMap
};

var My_result = {
  $$return: $$return,
  map: map,
  map_err: map_err,
  Infix: Infix
};

function $$return$1(x) {
  return Caml_option.some(x);
}

function flat_map(f, x) {
  return Belt_Option.flatMap(x, f);
}

var My_opt = {
  $$return: $$return$1,
  flat_map: flat_map
};

function take(i, xs) {
  return Belt_Option.getWithDefault(Belt_List.take(xs, i), /* [] */0);
}

function map$1(f, xs) {
  return Belt_List.map(xs, f);
}

function mapi(f, xs) {
  return Belt_List.mapWithIndex(xs, f);
}

function find_map(f, xs) {
  return Belt_Option.flatMap(Belt_List.getBy(xs, (function (x) {
                    return Curry._1(f, x) !== undefined;
                  })), f);
}

function fold_left(f, init, xs) {
  return Belt_List.reduce(xs, init, f);
}

var My_list = {
  take: take,
  map: map$1,
  mapi: mapi,
  find_map: find_map,
  fold_left: fold_left
};

export {
  My_result ,
  My_opt ,
  My_list ,
}
/* No side effect */
