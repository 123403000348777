// Generated by Melange


function equal(x, y) {
  return x === y;
}

var max = 2147483647;

var min = -2147483648;

export {
  equal ,
  max ,
  min ,
}
/* No side effect */
