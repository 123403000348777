// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";
import * as Stdlib__List from "melange/stdlib_modules/list.bs.js";
import * as Decoders_bs__Decoders_util from "./decoders_util.bs.js";

function Make(E) {
  var string = function (x) {
    return Curry._1(E.of_string, x);
  };
  var $$int = function (x) {
    return Curry._1(E.of_int, x);
  };
  var $$float = function (x) {
    return Curry._1(E.of_float, x);
  };
  var bool = function (x) {
    return Curry._1(E.of_bool, x);
  };
  var option = function (encoder, x) {
    if (x !== undefined) {
      return Curry._1(encoder, Caml_option.valFromOption(x));
    } else {
      return E.$$null;
    }
  };
  var list = function (encoder, xs) {
    return Curry._1(E.of_list, Decoders_bs__Decoders_util.My_list.map(Curry.__1(encoder), xs));
  };
  var obj$p = function (xs) {
    return Curry._1(E.of_key_value_pairs, xs);
  };
  var obj = function (xs) {
    return Curry._1(E.of_key_value_pairs, Stdlib__List.map((function (param) {
                      return [
                              Curry._1(E.of_string, param[0]),
                              param[1]
                            ];
                    }), xs));
  };
  var value = function (x) {
    return x;
  };
  var encode_value = function (encoder, x) {
    return Curry._1(encoder, x);
  };
  var encode_string = function (encoder, x) {
    return Curry._1(E.to_string, Curry._1(encoder, x));
  };
  return {
          string: string,
          $$int: $$int,
          $$float: $$float,
          bool: bool,
          $$null: E.$$null,
          option: option,
          list: list,
          obj: obj,
          obj$p: obj$p,
          value: value,
          encode_value: encode_value,
          encode_string: encode_string
        };
}

export {
  Make ,
}
/* Stdlib__List Not a pure module */
