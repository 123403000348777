// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Stdlib from "melange/./stdlib.bs.js";
import * as Caml_obj from "melange.runtime/caml_obj.bs.js";
import * as Belt_List from "melange.belt/./belt_List.bs.js";
import * as Belt_Array from "melange.belt/./belt_Array.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";

var onUnhandledException = {
  contents: (function (exn) {
      console.error("Unhandled exception in promise callback:");
      console.error(exn);
    })
};

function PromiseBox(p) {
    this.nested = p;
};

function unbox(value) {
    if (value instanceof PromiseBox)
        return value.nested;
    else
        return value;
}

function box(value) {
    if (value != null && typeof value.then === 'function')
        return new PromiseBox(value);
    else
        return value;
}

function make(executor) {
    return new Promise(function (resolve, reject) {
        var boxingResolve = function(value) {
            resolve(box(value));
        };
        executor(boxingResolve, reject);
    });
};

function resolved(value) {
    return Promise.resolve(box(value));
};

function then(promise, callback) {
    return promise.then(function (value) {
        try {
            return callback(unbox(value));
        }
        catch (exception) {
            onUnhandledException.contents(exception);
            return new Promise(function() {});
        }
    });
};

function catch_(promise, callback) {
    var safeCallback = function (error) {
        try {
            return callback(error);
        }
        catch (exception) {
            onUnhandledException.contents(exception);
            return new Promise(function() {});
        }
    };

    return promise.catch(safeCallback);
};
;

function pending(param) {
  var resolve = {
    contents: (function (prim) {
        
      })
  };
  var reject = {
    contents: (function (prim) {
        
      })
  };
  var p = make(function (resolve$p, reject$p) {
        resolve.contents = resolve$p;
        reject.contents = reject$p;
      });
  return [
          p,
          resolve.contents,
          reject.contents
        ];
}

function map(promise, callback) {
  return then(promise, (function (v) {
                return resolved(Curry._1(callback, v));
              }));
}

function get(promise, callback) {
  map(promise, callback);
}

function tap(promise, callback) {
  return map(promise, (function (v) {
                Curry._1(callback, v);
                return v;
              }));
}

function allArray(promises) {
  return map(Promise.all(promises), (function (promises) {
                return Belt_Array.map(promises, (function (prim) {
                              return unbox(prim);
                            }));
              }));
}

function all(promises) {
  return map(allArray(Belt_List.toArray(promises)), Belt_List.fromArray);
}

function all2(p1, p2) {
  return Promise.all([
              p1,
              p2
            ]);
}

function all3(p1, p2, p3) {
  return Promise.all([
              p1,
              p2,
              p3
            ]);
}

function all4(p1, p2, p3, p4) {
  return Promise.all([
              p1,
              p2,
              p3,
              p4
            ]);
}

function all5(p1, p2, p3, p4, p5) {
  return Promise.all([
              p1,
              p2,
              p3,
              p4,
              p5
            ]);
}

function all6(p1, p2, p3, p4, p5, p6) {
  return Promise.all([
              p1,
              p2,
              p3,
              p4,
              p5,
              p6
            ]);
}

function race(promises) {
  if (Caml_obj.caml_equal(promises, /* [] */0)) {
    throw {
          RE_EXN_ID: Stdlib.Invalid_argument,
          _1: "Promise.race([]) would be pending forever",
          Error: new Error()
        };
  }
  return Promise.race(Belt_List.toArray(promises));
}

function toResult(promise) {
  return catch_(map(promise, (function (v) {
                    return {
                            TAG: /* Ok */0,
                            _0: v
                          };
                  })), (function (e) {
                return resolved({
                            TAG: /* Error */1,
                            _0: e
                          });
              }));
}

function fromResult(promise) {
  return then(promise, (function (v) {
                if (v.TAG === /* Ok */0) {
                  return resolved(v._0);
                } else {
                  return Promise.reject(v._0);
                }
              }));
}

function pending$1(param) {
  var match = pending(undefined);
  return [
          match[0],
          match[1]
        ];
}

function exec(executor) {
  var match = pending$1(undefined);
  Curry._1(executor, match[1]);
  return match[0];
}

function resolved$1(prim) {
  return resolved(prim);
}

function flatMap(prim0, prim1) {
  return then(prim0, prim1);
}

function flatMapOk(promise, callback) {
  return then(promise, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return Curry._1(callback, result._0);
                } else {
                  return resolved(result);
                }
              }));
}

function flatMapError(promise, callback) {
  return then(promise, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return resolved(result);
                } else {
                  return Curry._1(callback, result._0);
                }
              }));
}

function mapOk(promise, callback) {
  return map(promise, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: Curry._1(callback, result._0)
                        };
                } else {
                  return result;
                }
              }));
}

function mapError(promise, callback) {
  return map(promise, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return result;
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Curry._1(callback, result._0)
                        };
                }
              }));
}

function getOk(promise, callback) {
  get(promise, (function (result) {
          if (result.TAG === /* Ok */0) {
            return Curry._1(callback, result._0);
          }
          
        }));
}

function getError(promise, callback) {
  get(promise, (function (result) {
          if (result.TAG === /* Ok */0) {
            return ;
          } else {
            return Curry._1(callback, result._0);
          }
        }));
}

function tapOk(promise, callback) {
  getOk(promise, callback);
  return promise;
}

function tapError(promise, callback) {
  getError(promise, callback);
  return promise;
}

function allOkArray(promises) {
  var promiseCount = promises.length;
  if (promiseCount === 0) {
    return resolved({
                TAG: /* Ok */0,
                _0: []
              });
  }
  var resultValues = Belt_Array.make(promiseCount, undefined);
  var resultCount = {
    contents: 0
  };
  var match = pending$1(undefined);
  var resolve = match[1];
  var match$1 = pending$1(undefined);
  var removeCallbacks = match$1[1];
  var callbackRemover = match$1[0];
  Belt_Array.forEachWithIndex(promises, (function (index, promise) {
          var wrapped = race({
                hd: promise,
                tl: {
                  hd: callbackRemover,
                  tl: /* [] */0
                }
              });
          get(wrapped, (function (result) {
                  if (result.TAG === /* Ok */0) {
                    Belt_Array.setExn(resultValues, index, Caml_option.some(result._0));
                    resultCount.contents = resultCount.contents + 1 | 0;
                    if (resultCount.contents < promiseCount) {
                      return ;
                    }
                    var values = Belt_Array.map(resultValues, (function (v) {
                            if (v !== undefined) {
                              return Caml_option.valFromOption(v);
                            }
                            throw {
                                  RE_EXN_ID: "Assert_failure",
                                  _1: [
                                    "promise.re",
                                    306,
                                    24
                                  ],
                                  Error: new Error()
                                };
                          }));
                    return Curry._1(resolve, {
                                TAG: /* Ok */0,
                                _0: values
                              });
                  }
                  var e = result._0;
                  Curry._1(resolve, {
                        TAG: /* Error */1,
                        _0: e
                      });
                  Curry._1(removeCallbacks, {
                        TAG: /* Error */1,
                        _0: e
                      });
                }));
        }));
  return match[0];
}

function allOk(promises) {
  return mapOk(allOkArray(Belt_List.toArray(promises)), Belt_List.fromArray);
}

function allOk2(p1, p2) {
  return allOkArray([
              p1,
              p2
            ]);
}

function allOk3(p1, p2, p3) {
  return allOkArray([
              p1,
              p2,
              p3
            ]);
}

function allOk4(p1, p2, p3, p4) {
  return allOkArray([
              p1,
              p2,
              p3,
              p4
            ]);
}

function allOk5(p1, p2, p3, p4, p5) {
  return allOkArray([
              p1,
              p2,
              p3,
              p4,
              p5
            ]);
}

function allOk6(p1, p2, p3, p4, p5, p6) {
  return allOkArray([
              p1,
              p2,
              p3,
              p4,
              p5,
              p6
            ]);
}

var Operators = {
  $great$pipe$eq: mapOk,
  $great$great$eq: flatMapOk
};

function flatMapSome(promise, callback) {
  return then(promise, (function (option) {
                if (option !== undefined) {
                  return Curry._1(callback, Caml_option.valFromOption(option));
                } else {
                  return resolved(undefined);
                }
              }));
}

function mapSome(promise, callback) {
  return map(promise, (function (option) {
                if (option !== undefined) {
                  return Caml_option.some(Curry._1(callback, Caml_option.valFromOption(option)));
                }
                
              }));
}

function getSome(promise, callback) {
  get(promise, (function (option) {
          if (option !== undefined) {
            return Curry._1(callback, Caml_option.valFromOption(option));
          }
          
        }));
}

function tapSome(promise, callback) {
  getSome(promise, callback);
  return promise;
}

var PipeFirst = {};

function Js_resolved(prim) {
  return resolved(prim);
}

function Js_rejected(prim) {
  return Promise.reject(prim);
}

function Js_flatMap(prim0, prim1) {
  return then(prim0, prim1);
}

function Js_catch(prim0, prim1) {
  return catch_(prim0, prim1);
}

function Js_relax(prim) {
  return prim;
}

function Js_fromBsPromise(prim) {
  return prim;
}

function Js_toBsPromise(prim) {
  return prim;
}

var Js = {
  pending: pending,
  resolved: Js_resolved,
  rejected: Js_rejected,
  get: get,
  tap: tap,
  map: map,
  flatMap: Js_flatMap,
  $$catch: Js_catch,
  all: all,
  race: race,
  relax: Js_relax,
  toResult: toResult,
  fromResult: fromResult,
  fromBsPromise: Js_fromBsPromise,
  toBsPromise: Js_toBsPromise
};

export {
  pending$1 as pending,
  resolved$1 as resolved,
  exec ,
  get ,
  tap ,
  map ,
  flatMap ,
  getOk ,
  tapOk ,
  mapOk ,
  flatMapOk ,
  getError ,
  tapError ,
  mapError ,
  flatMapError ,
  Operators ,
  getSome ,
  tapSome ,
  mapSome ,
  flatMapSome ,
  race ,
  all ,
  allArray ,
  all2 ,
  all3 ,
  all4 ,
  all5 ,
  all6 ,
  allOk ,
  allOkArray ,
  allOk2 ,
  allOk3 ,
  allOk4 ,
  allOk5 ,
  allOk6 ,
  Js ,
  PipeFirst ,
  onUnhandledException ,
}
/*  Not a pure module */
