// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Stdlib from "../stdlib.bs.js";
import * as Caml_bytes from "melange.runtime/caml_bytes.bs.js";
import * as Caml_string from "melange.runtime/caml_string.bs.js";
import * as Stdlib__Seq from "./seq.bs.js";
import * as Stdlib__Sys from "./sys.bs.js";
import * as Stdlib__Bytes from "./bytes.bs.js";
import * as Stdlib__String from "./string.bs.js";

function create(n) {
  var n$1 = n < 1 ? 1 : n;
  var n$2 = n$1 > Stdlib__Sys.max_string_length ? Stdlib__Sys.max_string_length : n$1;
  var s = Caml_bytes.caml_create_bytes(n$2);
  return {
          buffer: s,
          position: 0,
          length: n$2,
          initial_buffer: s
        };
}

function contents(b) {
  return Stdlib__Bytes.sub_string(b.buffer, 0, b.position);
}

function to_bytes(b) {
  return Stdlib__Bytes.sub(b.buffer, 0, b.position);
}

function sub(b, ofs, len) {
  if (ofs < 0 || len < 0 || ofs > (b.position - len | 0)) {
    return Stdlib.invalid_arg("Buffer.sub");
  } else {
    return Stdlib__Bytes.sub_string(b.buffer, ofs, len);
  }
}

function blit(src, srcoff, dst, dstoff, len) {
  if (len < 0 || srcoff < 0 || srcoff > (src.position - len | 0) || dstoff < 0 || dstoff > (dst.length - len | 0)) {
    return Stdlib.invalid_arg("Buffer.blit");
  } else {
    return Caml_bytes.caml_blit_bytes(src.buffer, srcoff, dst, dstoff, len);
  }
}

function nth(b, ofs) {
  if (ofs < 0 || ofs >= b.position) {
    return Stdlib.invalid_arg("Buffer.nth");
  } else {
    return b.buffer[ofs];
  }
}

function length(b) {
  return b.position;
}

function clear(b) {
  b.position = 0;
}

function reset(b) {
  b.position = 0;
  b.buffer = b.initial_buffer;
  b.length = b.buffer.length;
}

function resize(b, more) {
  var old_pos = b.position;
  var old_len = b.length;
  var new_len = old_len;
  while((old_pos + more | 0) > new_len) {
    new_len = (new_len << 1);
  };
  if (new_len > Stdlib__Sys.max_string_length) {
    if ((old_pos + more | 0) <= Stdlib__Sys.max_string_length) {
      new_len = Stdlib__Sys.max_string_length;
    } else {
      Stdlib.failwith("Buffer.add: cannot grow buffer");
    }
  }
  var new_buffer = Caml_bytes.caml_create_bytes(new_len);
  Stdlib__Bytes.blit(b.buffer, 0, new_buffer, 0, b.position);
  b.buffer = new_buffer;
  b.length = new_len;
  if ((b.position + more | 0) > b.length) {
    throw {
          RE_EXN_ID: "Assert_failure",
          _1: [
            "buffer.ml",
            93,
            2
          ],
          Error: new Error()
        };
  }
  if ((old_pos + more | 0) > b.length) {
    throw {
          RE_EXN_ID: "Assert_failure",
          _1: [
            "buffer.ml",
            94,
            2
          ],
          Error: new Error()
        };
  }
  
}

function add_char(b, c) {
  var pos = b.position;
  if (pos >= b.length) {
    resize(b, 1);
  }
  b.buffer[pos] = c;
  b.position = pos + 1 | 0;
}

function add_substring(b, s, offset, len) {
  if (offset < 0 || len < 0 || offset > (s.length - len | 0)) {
    Stdlib.invalid_arg("Buffer.add_substring/add_subbytes");
  }
  var new_position = b.position + len | 0;
  if (new_position > b.length) {
    resize(b, len);
  }
  Caml_bytes.caml_blit_string(s, offset, b.buffer, b.position, len);
  b.position = new_position;
}

function add_subbytes(b, s, offset, len) {
  add_substring(b, Caml_bytes.bytes_to_string(s), offset, len);
}

function add_string(b, s) {
  var len = s.length;
  var new_position = b.position + len | 0;
  if (new_position > b.length) {
    resize(b, len);
  }
  Caml_bytes.caml_blit_string(s, 0, b.buffer, b.position, len);
  b.position = new_position;
}

function add_bytes(b, s) {
  add_string(b, Caml_bytes.bytes_to_string(s));
}

function add_buffer(b, bs) {
  add_subbytes(b, bs.buffer, 0, bs.position);
}

function really_input_up_to(ic, buf, ofs, len) {
  var _already_read = 0;
  var _ofs = ofs;
  var _to_read = len;
  while(true) {
    var to_read = _to_read;
    var ofs$1 = _ofs;
    var already_read = _already_read;
    if (to_read === 0) {
      return already_read;
    }
    var r = Stdlib.input(ic, buf, ofs$1, to_read);
    if (r === 0) {
      return already_read;
    }
    var already_read$1 = already_read + r | 0;
    var ofs$2 = ofs$1 + r | 0;
    var to_read$1 = to_read - r | 0;
    _to_read = to_read$1;
    _ofs = ofs$2;
    _already_read = already_read$1;
    continue ;
  };
}

function unsafe_add_channel_up_to(b, ic, len) {
  if ((b.position + len | 0) > b.length) {
    resize(b, len);
  }
  var n = really_input_up_to(ic, b.buffer, b.position, len);
  if ((b.position + n | 0) > b.length) {
    throw {
          RE_EXN_ID: "Assert_failure",
          _1: [
            "buffer.ml",
            215,
            2
          ],
          Error: new Error()
        };
  }
  b.position = b.position + n | 0;
  return n;
}

function add_channel(b, ic, len) {
  if (len < 0 || len > Stdlib__Sys.max_string_length) {
    Stdlib.invalid_arg("Buffer.add_channel");
  }
  var n = unsafe_add_channel_up_to(b, ic, len);
  if (n < len) {
    throw {
          RE_EXN_ID: Stdlib.End_of_file,
          Error: new Error()
        };
  }
  
}

function output_buffer(oc, b) {
  Stdlib.output(oc, b.buffer, 0, b.position);
}

function closing(param) {
  if (param === 40) {
    return /* ')' */41;
  }
  if (param === 123) {
    return /* '}' */125;
  }
  throw {
        RE_EXN_ID: "Assert_failure",
        _1: [
          "buffer.ml",
          234,
          9
        ],
        Error: new Error()
      };
}

function advance_to_closing(opening, closing, k, s, start) {
  var _k = k;
  var _i = start;
  var lim = s.length;
  while(true) {
    var i = _i;
    var k$1 = _k;
    if (i >= lim) {
      throw {
            RE_EXN_ID: Stdlib.Not_found,
            Error: new Error()
          };
    }
    if (Caml_string.get(s, i) === opening) {
      _i = i + 1 | 0;
      _k = k$1 + 1 | 0;
      continue ;
    }
    if (Caml_string.get(s, i) === closing) {
      if (k$1 === 0) {
        return i;
      }
      _i = i + 1 | 0;
      _k = k$1 - 1 | 0;
      continue ;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function advance_to_non_alpha(s, start) {
  var _i = start;
  var lim = s.length;
  while(true) {
    var i = _i;
    if (i >= lim) {
      return lim;
    }
    var match = Caml_string.get(s, i);
    if (match >= 91) {
      if (match >= 97) {
        if (match >= 123) {
          return i;
        }
        
      } else if (match !== 95) {
        return i;
      }
      
    } else if (match >= 58) {
      if (match < 65) {
        return i;
      }
      
    } else if (match < 48) {
      return i;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function find_ident(s, start, lim) {
  if (start >= lim) {
    throw {
          RE_EXN_ID: Stdlib.Not_found,
          Error: new Error()
        };
  }
  var c = Caml_string.get(s, start);
  if (c !== 40 && c !== 123) {
    var stop = advance_to_non_alpha(s, start + 1 | 0);
    return [
            Stdlib__String.sub(s, start, stop - start | 0),
            stop
          ];
  }
  var new_start = start + 1 | 0;
  var stop$1 = advance_to_closing(c, closing(c), 0, s, new_start);
  return [
          Stdlib__String.sub(s, new_start, (stop$1 - start | 0) - 1 | 0),
          stop$1 + 1 | 0
        ];
}

function add_substitute(b, f, s) {
  var lim = s.length;
  var _previous = /* ' ' */32;
  var _i = 0;
  while(true) {
    var i = _i;
    var previous = _previous;
    if (i >= lim) {
      if (previous === /* '\\' */92) {
        return add_char(b, previous);
      } else {
        return ;
      }
    }
    var current = Caml_string.get(s, i);
    if (current !== 36) {
      if (previous === /* '\\' */92) {
        add_char(b, /* '\\' */92);
        add_char(b, current);
        _i = i + 1 | 0;
        _previous = /* ' ' */32;
        continue ;
      }
      if (current !== 92) {
        add_char(b, current);
        _i = i + 1 | 0;
        _previous = current;
        continue ;
      }
      _i = i + 1 | 0;
      _previous = current;
      continue ;
    }
    if (previous === /* '\\' */92) {
      add_char(b, current);
      _i = i + 1 | 0;
      _previous = /* ' ' */32;
      continue ;
    }
    var j = i + 1 | 0;
    var match = find_ident(s, j, lim);
    add_string(b, Curry._1(f, match[0]));
    _i = match[1];
    _previous = /* ' ' */32;
    continue ;
  };
}

function truncate(b, len) {
  if (len < 0 || len > b.position) {
    return Stdlib.invalid_arg("Buffer.truncate");
  } else {
    b.position = len;
    return ;
  }
}

function to_seq(b) {
  var aux = function (i, param) {
    if (i >= b.position) {
      return /* Nil */0;
    }
    var x = b.buffer[i];
    var partial_arg = i + 1 | 0;
    return /* Cons */{
            _0: x,
            _1: (function (param) {
                return aux(partial_arg, param);
              })
          };
  };
  return function (param) {
    return aux(0, param);
  };
}

function to_seqi(b) {
  var aux = function (i, param) {
    if (i >= b.position) {
      return /* Nil */0;
    }
    var x = b.buffer[i];
    var partial_arg = i + 1 | 0;
    return /* Cons */{
            _0: [
              i,
              x
            ],
            _1: (function (param) {
                return aux(partial_arg, param);
              })
          };
  };
  return function (param) {
    return aux(0, param);
  };
}

function add_seq(b, seq) {
  Stdlib__Seq.iter((function (param) {
          return add_char(b, param);
        }), seq);
}

function of_seq(i) {
  var b = create(32);
  Stdlib__Seq.iter((function (param) {
          return add_char(b, param);
        }), i);
  return b;
}

export {
  create ,
  contents ,
  to_bytes ,
  sub ,
  blit ,
  nth ,
  length ,
  clear ,
  reset ,
  output_buffer ,
  truncate ,
  add_char ,
  add_string ,
  add_bytes ,
  add_substring ,
  add_subbytes ,
  add_substitute ,
  add_buffer ,
  add_channel ,
  to_seq ,
  to_seqi ,
  add_seq ,
  of_seq ,
}
/* Stdlib Not a pure module */
