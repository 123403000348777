// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Stdlib from "../stdlib.bs.js";
import * as Caml_bytes from "melange.runtime/caml_bytes.bs.js";
import * as Stdlib__Int from "./int.bs.js";
import * as Stdlib__Seq from "./seq.bs.js";
import * as Stdlib__Sys from "./sys.bs.js";
import * as Stdlib__Char from "./char.bs.js";
import * as Caml_js_exceptions from "melange.runtime/caml_js_exceptions.bs.js";

function make(n, c) {
  var s = Caml_bytes.caml_create_bytes(n);
  Caml_bytes.caml_fill_bytes(s, 0, n, c);
  return s;
}

function init(n, f) {
  var s = Caml_bytes.caml_create_bytes(n);
  for(var i = 0; i < n; ++i){
    s[i] = Curry._1(f, i);
  }
  return s;
}

var empty = [];

function copy(s) {
  var len = s.length;
  var r = Caml_bytes.caml_create_bytes(len);
  Caml_bytes.caml_blit_bytes(s, 0, r, 0, len);
  return r;
}

function to_string(b) {
  return Caml_bytes.bytes_to_string(copy(b));
}

function of_string(s) {
  return copy(Caml_bytes.bytes_of_string(s));
}

function sub(s, ofs, len) {
  if (ofs < 0 || len < 0 || ofs > (s.length - len | 0)) {
    return Stdlib.invalid_arg("String.sub / Bytes.sub");
  }
  var r = Caml_bytes.caml_create_bytes(len);
  Caml_bytes.caml_blit_bytes(s, ofs, r, 0, len);
  return r;
}

function sub_string(b, ofs, len) {
  return Caml_bytes.bytes_to_string(sub(b, ofs, len));
}

function $plus$plus(a, b) {
  var c = a + b | 0;
  var match = a < 0;
  var match$1 = b < 0;
  var match$2 = c < 0;
  if (match) {
    if (match$1 && !match$2) {
      return Stdlib.invalid_arg("Bytes.extend");
    } else {
      return c;
    }
  } else if (match$1 || !match$2) {
    return c;
  } else {
    return Stdlib.invalid_arg("Bytes.extend");
  }
}

function extend(s, left, right) {
  var len = $plus$plus($plus$plus(s.length, left), right);
  var r = Caml_bytes.caml_create_bytes(len);
  var match = left < 0 ? [
      -left | 0,
      0
    ] : [
      0,
      left
    ];
  var dstoff = match[1];
  var srcoff = match[0];
  var cpylen = Stdlib__Int.min(s.length - srcoff | 0, len - dstoff | 0);
  if (cpylen > 0) {
    Caml_bytes.caml_blit_bytes(s, srcoff, r, dstoff, cpylen);
  }
  return r;
}

function fill(s, ofs, len, c) {
  if (ofs < 0 || len < 0 || ofs > (s.length - len | 0)) {
    return Stdlib.invalid_arg("String.fill / Bytes.fill");
  } else {
    return Caml_bytes.caml_fill_bytes(s, ofs, len, c);
  }
}

function blit(s1, ofs1, s2, ofs2, len) {
  if (len < 0 || ofs1 < 0 || ofs1 > (s1.length - len | 0) || ofs2 < 0 || ofs2 > (s2.length - len | 0)) {
    return Stdlib.invalid_arg("Bytes.blit");
  } else {
    return Caml_bytes.caml_blit_bytes(s1, ofs1, s2, ofs2, len);
  }
}

function blit_string(s1, ofs1, s2, ofs2, len) {
  if (len < 0 || ofs1 < 0 || ofs1 > (s1.length - len | 0) || ofs2 < 0 || ofs2 > (s2.length - len | 0)) {
    return Stdlib.invalid_arg("String.blit / Bytes.blit_string");
  } else {
    return Caml_bytes.caml_blit_string(s1, ofs1, s2, ofs2, len);
  }
}

function iter(f, a) {
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    Curry._1(f, a[i]);
  }
}

function iteri(f, a) {
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    Curry._2(f, i, a[i]);
  }
}

function ensure_ge(x, y) {
  if (x >= y) {
    return x;
  } else {
    return Stdlib.invalid_arg("Bytes.concat");
  }
}

function sum_lengths(_acc, seplen, _param) {
  while(true) {
    var param = _param;
    var acc = _acc;
    if (!param) {
      return acc;
    }
    var hd = param.hd;
    if (!param.tl) {
      return hd.length + acc | 0;
    }
    _param = param.tl;
    _acc = ensure_ge((hd.length + seplen | 0) + acc | 0, acc);
    continue ;
  };
}

function concat(sep, l) {
  if (!l) {
    return empty;
  }
  var seplen = sep.length;
  var dst = Caml_bytes.caml_create_bytes(sum_lengths(0, seplen, l));
  var _pos = 0;
  var _param = l;
  while(true) {
    var param = _param;
    var pos = _pos;
    if (!param) {
      return dst;
    }
    var hd = param.hd;
    if (param.tl) {
      Caml_bytes.caml_blit_bytes(hd, 0, dst, pos, hd.length);
      Caml_bytes.caml_blit_bytes(sep, 0, dst, pos + hd.length | 0, seplen);
      _param = param.tl;
      _pos = (pos + hd.length | 0) + seplen | 0;
      continue ;
    }
    Caml_bytes.caml_blit_bytes(hd, 0, dst, pos, hd.length);
    return dst;
  };
}

function cat(s1, s2) {
  var l1 = s1.length;
  var l2 = s2.length;
  var r = Caml_bytes.caml_create_bytes(l1 + l2 | 0);
  Caml_bytes.caml_blit_bytes(s1, 0, r, 0, l1);
  Caml_bytes.caml_blit_bytes(s2, 0, r, l1, l2);
  return r;
}

function is_space(param) {
  if (param > 13 || param < 9) {
    return param === 32;
  } else {
    return param !== 11;
  }
}

function trim(s) {
  var len = s.length;
  var i = 0;
  while(i < len && is_space(s[i])) {
    i = i + 1 | 0;
  };
  var j = len - 1 | 0;
  while(j >= i && is_space(s[j])) {
    j = j - 1 | 0;
  };
  if (j >= i) {
    return sub(s, i, (j - i | 0) + 1 | 0);
  } else {
    return empty;
  }
}

function escaped(s) {
  var n = 0;
  for(var i = 0 ,i_finish = s.length; i < i_finish; ++i){
    var match = s[i];
    n = n + (
      match >= 32 ? (
          match > 92 || match < 34 ? (
              match >= 127 ? 4 : 1
            ) : (
              match > 91 || match < 35 ? 2 : 1
            )
        ) : (
          match >= 11 ? (
              match !== 13 ? 4 : 2
            ) : (
              match >= 8 ? 2 : 4
            )
        )
    ) | 0;
  }
  if (n === s.length) {
    return copy(s);
  }
  var s$p = Caml_bytes.caml_create_bytes(n);
  n = 0;
  for(var i$1 = 0 ,i_finish$1 = s.length; i$1 < i_finish$1; ++i$1){
    var c = s[i$1];
    var exit = 0;
    if (c >= 35) {
      if (c !== 92) {
        if (c >= 127) {
          exit = 1;
        } else {
          s$p[n] = c;
        }
      } else {
        exit = 2;
      }
    } else if (c >= 32) {
      if (c >= 34) {
        exit = 2;
      } else {
        s$p[n] = c;
      }
    } else if (c >= 14) {
      exit = 1;
    } else {
      switch (c) {
        case 8 :
            s$p[n] = /* '\\' */92;
            n = n + 1 | 0;
            s$p[n] = /* 'b' */98;
            break;
        case 9 :
            s$p[n] = /* '\\' */92;
            n = n + 1 | 0;
            s$p[n] = /* 't' */116;
            break;
        case 10 :
            s$p[n] = /* '\\' */92;
            n = n + 1 | 0;
            s$p[n] = /* 'n' */110;
            break;
        case 0 :
        case 1 :
        case 2 :
        case 3 :
        case 4 :
        case 5 :
        case 6 :
        case 7 :
        case 11 :
        case 12 :
            exit = 1;
            break;
        case 13 :
            s$p[n] = /* '\\' */92;
            n = n + 1 | 0;
            s$p[n] = /* 'r' */114;
            break;
        
      }
    }
    switch (exit) {
      case 1 :
          s$p[n] = /* '\\' */92;
          n = n + 1 | 0;
          s$p[n] = 48 + (c / 100 | 0) | 0;
          n = n + 1 | 0;
          s$p[n] = 48 + (c / 10 | 0) % 10 | 0;
          n = n + 1 | 0;
          s$p[n] = 48 + c % 10 | 0;
          break;
      case 2 :
          s$p[n] = /* '\\' */92;
          n = n + 1 | 0;
          s$p[n] = c;
          break;
      
    }
    n = n + 1 | 0;
  }
  return s$p;
}

function map(f, s) {
  var l = s.length;
  if (l === 0) {
    return s;
  }
  var r = Caml_bytes.caml_create_bytes(l);
  for(var i = 0; i < l; ++i){
    r[i] = Curry._1(f, s[i]);
  }
  return r;
}

function mapi(f, s) {
  var l = s.length;
  if (l === 0) {
    return s;
  }
  var r = Caml_bytes.caml_create_bytes(l);
  for(var i = 0; i < l; ++i){
    r[i] = Curry._2(f, i, s[i]);
  }
  return r;
}

function fold_left(f, x, a) {
  var r = x;
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    r = Curry._2(f, r, a[i]);
  }
  return r;
}

function fold_right(f, a, x) {
  var r = x;
  for(var i = a.length - 1 | 0; i >= 0; --i){
    r = Curry._2(f, a[i], r);
  }
  return r;
}

function exists(p, s) {
  var n = s.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return false;
    }
    if (Curry._1(p, s[i])) {
      return true;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function for_all(p, s) {
  var n = s.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return true;
    }
    if (!Curry._1(p, s[i])) {
      return false;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function uppercase_ascii(s) {
  return map(Stdlib__Char.uppercase_ascii, s);
}

function lowercase_ascii(s) {
  return map(Stdlib__Char.lowercase_ascii, s);
}

function apply1(f, s) {
  if (s.length === 0) {
    return s;
  }
  var r = copy(s);
  r[0] = Curry._1(f, s[0]);
  return r;
}

function capitalize_ascii(s) {
  return apply1(Stdlib__Char.uppercase_ascii, s);
}

function uncapitalize_ascii(s) {
  return apply1(Stdlib__Char.lowercase_ascii, s);
}

function starts_with(prefix, s) {
  var len_s = s.length;
  var len_pre = prefix.length;
  if (len_s >= len_pre) {
    var _i = 0;
    while(true) {
      var i = _i;
      if (i === len_pre) {
        return true;
      }
      if (s[i] !== prefix[i]) {
        return false;
      }
      _i = i + 1 | 0;
      continue ;
    };
  } else {
    return false;
  }
}

function ends_with(suffix, s) {
  var len_s = s.length;
  var len_suf = suffix.length;
  var diff = len_s - len_suf | 0;
  if (diff >= 0) {
    var _i = 0;
    while(true) {
      var i = _i;
      if (i === len_suf) {
        return true;
      }
      if (s[diff + i | 0] !== suffix[i]) {
        return false;
      }
      _i = i + 1 | 0;
      continue ;
    };
  } else {
    return false;
  }
}

function index_rec(s, lim, _i, c) {
  while(true) {
    var i = _i;
    if (i >= lim) {
      throw {
            RE_EXN_ID: Stdlib.Not_found,
            Error: new Error()
          };
    }
    if (s[i] === c) {
      return i;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function index(s, c) {
  return index_rec(s, s.length, 0, c);
}

function index_rec_opt(s, lim, _i, c) {
  while(true) {
    var i = _i;
    if (i >= lim) {
      return ;
    }
    if (s[i] === c) {
      return i;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function index_opt(s, c) {
  return index_rec_opt(s, s.length, 0, c);
}

function index_from(s, i, c) {
  var l = s.length;
  if (i < 0 || i > l) {
    return Stdlib.invalid_arg("String.index_from / Bytes.index_from");
  } else {
    return index_rec(s, l, i, c);
  }
}

function index_from_opt(s, i, c) {
  var l = s.length;
  if (i < 0 || i > l) {
    return Stdlib.invalid_arg("String.index_from_opt / Bytes.index_from_opt");
  } else {
    return index_rec_opt(s, l, i, c);
  }
}

function rindex_rec(s, _i, c) {
  while(true) {
    var i = _i;
    if (i < 0) {
      throw {
            RE_EXN_ID: Stdlib.Not_found,
            Error: new Error()
          };
    }
    if (s[i] === c) {
      return i;
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function rindex(s, c) {
  return rindex_rec(s, s.length - 1 | 0, c);
}

function rindex_from(s, i, c) {
  if (i < -1 || i >= s.length) {
    return Stdlib.invalid_arg("String.rindex_from / Bytes.rindex_from");
  } else {
    return rindex_rec(s, i, c);
  }
}

function rindex_rec_opt(s, _i, c) {
  while(true) {
    var i = _i;
    if (i < 0) {
      return ;
    }
    if (s[i] === c) {
      return i;
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function rindex_opt(s, c) {
  return rindex_rec_opt(s, s.length - 1 | 0, c);
}

function rindex_from_opt(s, i, c) {
  if (i < -1 || i >= s.length) {
    return Stdlib.invalid_arg("String.rindex_from_opt / Bytes.rindex_from_opt");
  } else {
    return rindex_rec_opt(s, i, c);
  }
}

function contains_from(s, i, c) {
  var l = s.length;
  if (i < 0 || i > l) {
    return Stdlib.invalid_arg("String.contains_from / Bytes.contains_from");
  }
  try {
    index_rec(s, l, i, c);
    return true;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Stdlib.Not_found) {
      return false;
    }
    throw exn;
  }
}

function contains(s, c) {
  return contains_from(s, 0, c);
}

function rcontains_from(s, i, c) {
  if (i < 0 || i >= s.length) {
    return Stdlib.invalid_arg("String.rcontains_from / Bytes.rcontains_from");
  }
  try {
    rindex_rec(s, i, c);
    return true;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Stdlib.Not_found) {
      return false;
    }
    throw exn;
  }
}

var compare = Caml_bytes.caml_bytes_compare;

function split_on_char(sep, s) {
  var r = /* [] */0;
  var j = s.length;
  for(var i = s.length - 1 | 0; i >= 0; --i){
    if (s[i] === sep) {
      r = {
        hd: sub(s, i + 1 | 0, (j - i | 0) - 1 | 0),
        tl: r
      };
      j = i;
    }
    
  }
  return {
          hd: sub(s, 0, j),
          tl: r
        };
}

function uppercase(s) {
  return map(Stdlib__Char.uppercase, s);
}

function lowercase(s) {
  return map(Stdlib__Char.lowercase, s);
}

function capitalize(s) {
  return apply1(Stdlib__Char.uppercase, s);
}

function uncapitalize(s) {
  return apply1(Stdlib__Char.lowercase, s);
}

function to_seq(s) {
  var aux = function (i, param) {
    if (i === s.length) {
      return /* Nil */0;
    }
    var x = Caml_bytes.get(s, i);
    var partial_arg = i + 1 | 0;
    return /* Cons */{
            _0: x,
            _1: (function (param) {
                return aux(partial_arg, param);
              })
          };
  };
  return function (param) {
    return aux(0, param);
  };
}

function to_seqi(s) {
  var aux = function (i, param) {
    if (i === s.length) {
      return /* Nil */0;
    }
    var x = Caml_bytes.get(s, i);
    var partial_arg = i + 1 | 0;
    return /* Cons */{
            _0: [
              i,
              x
            ],
            _1: (function (param) {
                return aux(partial_arg, param);
              })
          };
  };
  return function (param) {
    return aux(0, param);
  };
}

function of_seq(i) {
  var n = {
    contents: 0
  };
  var buf = {
    contents: make(256, /* '\000' */0)
  };
  var resize = function (param) {
    var new_len = Stdlib__Int.min((buf.contents.length << 1), Stdlib__Sys.max_string_length);
    if (buf.contents.length === new_len) {
      Stdlib.failwith("Bytes.of_seq: cannot grow bytes");
    }
    var new_buf = make(new_len, /* '\000' */0);
    blit(buf.contents, 0, new_buf, 0, n.contents);
    buf.contents = new_buf;
  };
  Stdlib__Seq.iter((function (c) {
          if (n.contents === buf.contents.length) {
            resize(undefined);
          }
          Caml_bytes.set(buf.contents, n.contents, c);
          n.contents = n.contents + 1 | 0;
        }), i);
  return sub(buf.contents, 0, n.contents);
}

var equal = Caml_bytes.caml_bytes_equal;

var unsafe_to_string = Caml_bytes.bytes_to_string;

var unsafe_of_string = Caml_bytes.bytes_of_string;

export {
  make ,
  init ,
  empty ,
  copy ,
  of_string ,
  to_string ,
  sub ,
  sub_string ,
  extend ,
  fill ,
  blit ,
  blit_string ,
  concat ,
  cat ,
  iter ,
  iteri ,
  map ,
  mapi ,
  fold_left ,
  fold_right ,
  for_all ,
  exists ,
  trim ,
  escaped ,
  index ,
  index_opt ,
  rindex ,
  rindex_opt ,
  index_from ,
  index_from_opt ,
  rindex_from ,
  rindex_from_opt ,
  contains ,
  contains_from ,
  rcontains_from ,
  uppercase ,
  lowercase ,
  capitalize ,
  uncapitalize ,
  uppercase_ascii ,
  lowercase_ascii ,
  capitalize_ascii ,
  uncapitalize_ascii ,
  compare ,
  equal ,
  starts_with ,
  ends_with ,
  unsafe_to_string ,
  unsafe_of_string ,
  split_on_char ,
  to_seq ,
  to_seqi ,
  of_seq ,
}
/* Stdlib Not a pure module */
