// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Stdlib from "../stdlib.bs.js";
import * as Caml_obj from "melange.runtime/caml_obj.bs.js";
import * as Caml_array from "melange.runtime/caml_array.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";
import * as Stdlib__Seq from "./seq.bs.js";
import * as Caml_exceptions from "melange.runtime/caml_exceptions.bs.js";
import * as Caml_js_exceptions from "melange.runtime/caml_js_exceptions.bs.js";

var make_float = Caml_array.make_float;

var Floatarray = {};

function init(l, f) {
  if (l === 0) {
    return [];
  }
  if (l < 0) {
    return Stdlib.invalid_arg("Array.init");
  }
  var res = Caml_array.make(l, Curry._1(f, 0));
  for(var i = 1; i < l; ++i){
    res[i] = Curry._1(f, i);
  }
  return res;
}

function make_matrix(sx, sy, init) {
  var res = Caml_array.make(sx, []);
  for(var x = 0; x < sx; ++x){
    res[x] = Caml_array.make(sy, init);
  }
  return res;
}

function copy(a) {
  var l = a.length;
  if (l === 0) {
    return [];
  } else {
    return Caml_array.sub(a, 0, l);
  }
}

function append(a1, a2) {
  var l1 = a1.length;
  if (l1 === 0) {
    return copy(a2);
  } else if (a2.length === 0) {
    return Caml_array.sub(a1, 0, l1);
  } else {
    return a1.concat(a2);
  }
}

function sub(a, ofs, len) {
  if (ofs < 0 || len < 0 || ofs > (a.length - len | 0)) {
    return Stdlib.invalid_arg("Array.sub");
  } else {
    return Caml_array.sub(a, ofs, len);
  }
}

function fill(a, ofs, len, v) {
  if (ofs < 0 || len < 0 || ofs > (a.length - len | 0)) {
    return Stdlib.invalid_arg("Array.fill");
  }
  for(var i = ofs ,i_finish = ofs + len | 0; i < i_finish; ++i){
    a[i] = v;
  }
}

function blit(a1, ofs1, a2, ofs2, len) {
  if (len < 0 || ofs1 < 0 || ofs1 > (a1.length - len | 0) || ofs2 < 0 || ofs2 > (a2.length - len | 0)) {
    return Stdlib.invalid_arg("Array.blit");
  } else {
    return Caml_array.blit(a1, ofs1, a2, ofs2, len);
  }
}

function iter(f, a) {
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    Curry._1(f, a[i]);
  }
}

function iter2(f, a, b) {
  if (a.length !== b.length) {
    return Stdlib.invalid_arg("Array.iter2: arrays must have the same length");
  }
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    Curry._2(f, a[i], b[i]);
  }
}

function map(f, a) {
  var l = a.length;
  if (l === 0) {
    return [];
  }
  var r = Caml_array.make(l, Curry._1(f, a[0]));
  for(var i = 1; i < l; ++i){
    r[i] = Curry._1(f, a[i]);
  }
  return r;
}

function map2(f, a, b) {
  var la = a.length;
  var lb = b.length;
  if (la !== lb) {
    return Stdlib.invalid_arg("Array.map2: arrays must have the same length");
  }
  if (la === 0) {
    return [];
  }
  var r = Caml_array.make(la, Curry._2(f, a[0], b[0]));
  for(var i = 1; i < la; ++i){
    r[i] = Curry._2(f, a[i], b[i]);
  }
  return r;
}

function iteri(f, a) {
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    Curry._2(f, i, a[i]);
  }
}

function mapi(f, a) {
  var l = a.length;
  if (l === 0) {
    return [];
  }
  var r = Caml_array.make(l, Curry._2(f, 0, a[0]));
  for(var i = 1; i < l; ++i){
    r[i] = Curry._2(f, i, a[i]);
  }
  return r;
}

function to_list(a) {
  var _i = a.length - 1 | 0;
  var _res = /* [] */0;
  while(true) {
    var res = _res;
    var i = _i;
    if (i < 0) {
      return res;
    }
    _res = {
      hd: a[i],
      tl: res
    };
    _i = i - 1 | 0;
    continue ;
  };
}

function list_length(_accu, _param) {
  while(true) {
    var param = _param;
    var accu = _accu;
    if (!param) {
      return accu;
    }
    _param = param.tl;
    _accu = accu + 1 | 0;
    continue ;
  };
}

function of_list(l) {
  if (!l) {
    return [];
  }
  var a = Caml_array.make(list_length(0, l), l.hd);
  var _i = 1;
  var _param = l.tl;
  while(true) {
    var param = _param;
    var i = _i;
    if (!param) {
      return a;
    }
    a[i] = param.hd;
    _param = param.tl;
    _i = i + 1 | 0;
    continue ;
  };
}

function fold_left(f, x, a) {
  var r = x;
  for(var i = 0 ,i_finish = a.length; i < i_finish; ++i){
    r = Curry._2(f, r, a[i]);
  }
  return r;
}

function fold_left_map(f, acc, input_array) {
  var len = input_array.length;
  if (len === 0) {
    return [
            acc,
            []
          ];
  }
  var match = Curry._2(f, acc, input_array[0]);
  var output_array = Caml_array.make(len, match[1]);
  var acc$1 = match[0];
  for(var i = 1; i < len; ++i){
    var match$1 = Curry._2(f, acc$1, input_array[i]);
    acc$1 = match$1[0];
    output_array[i] = match$1[1];
  }
  return [
          acc$1,
          output_array
        ];
}

function fold_right(f, a, x) {
  var r = x;
  for(var i = a.length - 1 | 0; i >= 0; --i){
    r = Curry._2(f, a[i], r);
  }
  return r;
}

function exists(p, a) {
  var n = a.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return false;
    }
    if (Curry._1(p, a[i])) {
      return true;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function for_all(p, a) {
  var n = a.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return true;
    }
    if (!Curry._1(p, a[i])) {
      return false;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function for_all2(p, l1, l2) {
  var n1 = l1.length;
  var n2 = l2.length;
  if (n1 !== n2) {
    return Stdlib.invalid_arg("Array.for_all2");
  }
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n1) {
      return true;
    }
    if (!Curry._2(p, l1[i], l2[i])) {
      return false;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function exists2(p, l1, l2) {
  var n1 = l1.length;
  var n2 = l2.length;
  if (n1 !== n2) {
    return Stdlib.invalid_arg("Array.exists2");
  }
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n1) {
      return false;
    }
    if (Curry._2(p, l1[i], l2[i])) {
      return true;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function mem(x, a) {
  var n = a.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return false;
    }
    if (Caml_obj.caml_equal(a[i], x)) {
      return true;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function memq(x, a) {
  var n = a.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return false;
    }
    if (x === a[i]) {
      return true;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function find_opt(p, a) {
  var n = a.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return ;
    }
    var x = a[i];
    if (Curry._1(p, x)) {
      return Caml_option.some(x);
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function find_map(f, a) {
  var n = a.length;
  var _i = 0;
  while(true) {
    var i = _i;
    if (i === n) {
      return ;
    }
    var r = Curry._1(f, a[i]);
    if (r !== undefined) {
      return r;
    }
    _i = i + 1 | 0;
    continue ;
  };
}

function split(x) {
  if (Caml_obj.caml_equal(x, [])) {
    return [
            [],
            []
          ];
  }
  var match = x[0];
  var n = x.length;
  var a = Caml_array.make(n, match[0]);
  var b = Caml_array.make(n, match[1]);
  for(var i = 1; i < n; ++i){
    var match$1 = x[i];
    a[i] = match$1[0];
    b[i] = match$1[1];
  }
  return [
          a,
          b
        ];
}

function combine(a, b) {
  var na = a.length;
  var nb = b.length;
  if (na !== nb) {
    Stdlib.invalid_arg("Array.combine");
  }
  if (na === 0) {
    return [];
  }
  var x = Caml_array.make(na, [
        a[0],
        b[0]
      ]);
  for(var i = 1; i < na; ++i){
    x[i] = [
      a[i],
      b[i]
    ];
  }
  return x;
}

var Bottom = /* @__PURE__ */Caml_exceptions.create("Stdlib.Array.Bottom");

function sort(cmp, a) {
  var maxson = function (l, i) {
    var i31 = ((i + i | 0) + i | 0) + 1 | 0;
    var x = i31;
    if ((i31 + 2 | 0) < l) {
      if (Curry._2(cmp, Caml_array.get(a, i31), Caml_array.get(a, i31 + 1 | 0)) < 0) {
        x = i31 + 1 | 0;
      }
      if (Curry._2(cmp, Caml_array.get(a, x), Caml_array.get(a, i31 + 2 | 0)) < 0) {
        x = i31 + 2 | 0;
      }
      return x;
    }
    if ((i31 + 1 | 0) < l && Curry._2(cmp, Caml_array.get(a, i31), Caml_array.get(a, i31 + 1 | 0)) < 0) {
      return i31 + 1 | 0;
    }
    if (i31 < l) {
      return i31;
    }
    throw {
          RE_EXN_ID: Bottom,
          _1: i,
          Error: new Error()
        };
  };
  var trickle = function (l, i, e) {
    try {
      var _i = i;
      while(true) {
        var i$1 = _i;
        var j = maxson(l, i$1);
        if (Curry._2(cmp, Caml_array.get(a, j), e) <= 0) {
          return Caml_array.set(a, i$1, e);
        }
        Caml_array.set(a, i$1, Caml_array.get(a, j));
        _i = j;
        continue ;
      };
    }
    catch (raw_i){
      var i$2 = Caml_js_exceptions.internalToOCamlException(raw_i);
      if (i$2.RE_EXN_ID === Bottom) {
        return Caml_array.set(a, i$2._1, e);
      }
      throw i$2;
    }
  };
  var bubble = function (l, i) {
    try {
      var _i = i;
      while(true) {
        var i$1 = _i;
        var j = maxson(l, i$1);
        Caml_array.set(a, i$1, Caml_array.get(a, j));
        _i = j;
        continue ;
      };
    }
    catch (raw_i){
      var i$2 = Caml_js_exceptions.internalToOCamlException(raw_i);
      if (i$2.RE_EXN_ID === Bottom) {
        return i$2._1;
      }
      throw i$2;
    }
  };
  var trickleup = function (_i, e) {
    while(true) {
      var i = _i;
      var father = (i - 1 | 0) / 3 | 0;
      if (i === father) {
        throw {
              RE_EXN_ID: "Assert_failure",
              _1: [
                "array.ml",
                327,
                4
              ],
              Error: new Error()
            };
      }
      if (Curry._2(cmp, Caml_array.get(a, father), e) >= 0) {
        return Caml_array.set(a, i, e);
      }
      Caml_array.set(a, i, Caml_array.get(a, father));
      if (father <= 0) {
        return Caml_array.set(a, 0, e);
      }
      _i = father;
      continue ;
    };
  };
  var l = a.length;
  for(var i = ((l + 1 | 0) / 3 | 0) - 1 | 0; i >= 0; --i){
    trickle(l, i, Caml_array.get(a, i));
  }
  for(var i$1 = l - 1 | 0; i$1 >= 2; --i$1){
    var e = Caml_array.get(a, i$1);
    Caml_array.set(a, i$1, Caml_array.get(a, 0));
    trickleup(bubble(i$1, 0), e);
  }
  if (l <= 1) {
    return ;
  }
  var e$1 = Caml_array.get(a, 1);
  Caml_array.set(a, 1, Caml_array.get(a, 0));
  Caml_array.set(a, 0, e$1);
}

function stable_sort(cmp, a) {
  var merge = function (src1ofs, src1len, src2, src2ofs, src2len, dst, dstofs) {
    var src1r = src1ofs + src1len | 0;
    var src2r = src2ofs + src2len | 0;
    var _i1 = src1ofs;
    var _s1 = Caml_array.get(a, src1ofs);
    var _i2 = src2ofs;
    var _s2 = Caml_array.get(src2, src2ofs);
    var _d = dstofs;
    while(true) {
      var d = _d;
      var s2 = _s2;
      var i2 = _i2;
      var s1 = _s1;
      var i1 = _i1;
      if (Curry._2(cmp, s1, s2) <= 0) {
        Caml_array.set(dst, d, s1);
        var i1$1 = i1 + 1 | 0;
        if (i1$1 >= src1r) {
          return blit(src2, i2, dst, d + 1 | 0, src2r - i2 | 0);
        }
        _d = d + 1 | 0;
        _s1 = Caml_array.get(a, i1$1);
        _i1 = i1$1;
        continue ;
      }
      Caml_array.set(dst, d, s2);
      var i2$1 = i2 + 1 | 0;
      if (i2$1 >= src2r) {
        return blit(a, i1, dst, d + 1 | 0, src1r - i1 | 0);
      }
      _d = d + 1 | 0;
      _s2 = Caml_array.get(src2, i2$1);
      _i2 = i2$1;
      continue ;
    };
  };
  var isortto = function (srcofs, dst, dstofs, len) {
    for(var i = 0; i < len; ++i){
      var e = Caml_array.get(a, srcofs + i | 0);
      var j = (dstofs + i | 0) - 1 | 0;
      while(j >= dstofs && Curry._2(cmp, Caml_array.get(dst, j), e) > 0) {
        Caml_array.set(dst, j + 1 | 0, Caml_array.get(dst, j));
        j = j - 1 | 0;
      };
      Caml_array.set(dst, j + 1 | 0, e);
    }
  };
  var sortto = function (srcofs, dst, dstofs, len) {
    if (len <= 5) {
      return isortto(srcofs, dst, dstofs, len);
    }
    var l1 = len / 2 | 0;
    var l2 = len - l1 | 0;
    sortto(srcofs + l1 | 0, dst, dstofs + l1 | 0, l2);
    sortto(srcofs, a, srcofs + l2 | 0, l1);
    merge(srcofs + l2 | 0, l1, dst, dstofs + l1 | 0, l2, dst, dstofs);
  };
  var l = a.length;
  if (l <= 5) {
    return isortto(0, a, 0, l);
  }
  var l1 = l / 2 | 0;
  var l2 = l - l1 | 0;
  var t = Caml_array.make(l2, Caml_array.get(a, 0));
  sortto(l1, t, 0, l2);
  sortto(0, a, l2, l1);
  merge(l2, l1, t, 0, l2, a, 0);
}

function to_seq(a) {
  var aux = function (i, param) {
    if (i >= a.length) {
      return /* Nil */0;
    }
    var x = a[i];
    var partial_arg = i + 1 | 0;
    return /* Cons */{
            _0: x,
            _1: (function (param) {
                return aux(partial_arg, param);
              })
          };
  };
  return function (param) {
    return aux(0, param);
  };
}

function to_seqi(a) {
  var aux = function (i, param) {
    if (i >= a.length) {
      return /* Nil */0;
    }
    var x = a[i];
    var partial_arg = i + 1 | 0;
    return /* Cons */{
            _0: [
              i,
              x
            ],
            _1: (function (param) {
                return aux(partial_arg, param);
              })
          };
  };
  return function (param) {
    return aux(0, param);
  };
}

function of_seq(i) {
  var l = Stdlib__Seq.fold_left((function (acc, x) {
          return {
                  hd: x,
                  tl: acc
                };
        }), /* [] */0, i);
  if (!l) {
    return [];
  }
  var len = list_length(0, l);
  var a = Caml_array.make(len, l.hd);
  var _i = len - 2 | 0;
  var _param = l.tl;
  while(true) {
    var param = _param;
    var i$1 = _i;
    if (!param) {
      return a;
    }
    a[i$1] = param.hd;
    _param = param.tl;
    _i = i$1 - 1 | 0;
    continue ;
  };
}

var create_matrix = make_matrix;

var concat = Caml_array.concat;

var fast_sort = stable_sort;

export {
  make_float ,
  init ,
  make_matrix ,
  create_matrix ,
  append ,
  concat ,
  sub ,
  copy ,
  fill ,
  blit ,
  to_list ,
  of_list ,
  iter ,
  iteri ,
  map ,
  mapi ,
  fold_left ,
  fold_left_map ,
  fold_right ,
  iter2 ,
  map2 ,
  for_all ,
  exists ,
  for_all2 ,
  exists2 ,
  mem ,
  memq ,
  find_opt ,
  find_map ,
  split ,
  combine ,
  sort ,
  stable_sort ,
  fast_sort ,
  to_seq ,
  to_seqi ,
  of_seq ,
  Floatarray ,
}
/* Stdlib Not a pure module */
