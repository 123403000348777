// Generated by Melange


var id = {
  contents: 0
};

function create(str) {
  id.contents = id.contents + 1 | 0;
  return str + ("/" + id.contents);
}

function caml_is_extension(e) {
  if (e == null) {
    return false;
  } else {
    return typeof e.RE_EXN_ID === "string";
  }
}

function caml_exn_slot_name(x) {
  return x.RE_EXN_ID;
}

var caml_exn_slot_id = (function(x){
  if (x.RE_EXN_ID != null) {
    var parts = x.RE_EXN_ID.split("/");
    if (parts.length > 1) {
      return Number(parts[parts.length - 1])
    } else {
      return -1;
    }
  } else {
    return -1;
  }
});

export {
  id ,
  create ,
  caml_is_extension ,
  caml_exn_slot_name ,
  caml_exn_slot_id ,
}
/* No side effect */
