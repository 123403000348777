// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Stdlib from "melange/./stdlib.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";
import * as Stdlib__List from "melange/stdlib_modules/list.bs.js";
import * as Stdlib__Format from "melange/stdlib_modules/format.bs.js";
import * as Stdlib__Printf from "melange/stdlib_modules/printf.bs.js";
import * as CamlinternalLazy from "melange/stdlib_modules/camlinternalLazy.bs.js";
import * as Caml_js_exceptions from "melange.runtime/caml_js_exceptions.bs.js";
import * as Decoders_bs__Decoders_util from "./decoders_util.bs.js";

function Make(Decodeable) {
  var pp = Decodeable.pp;
  var pp_error = function (fmt, errors) {
    switch (errors.TAG | 0) {
      case /* Decoder_error */0 :
          var t = errors._1;
          var msg = errors._0;
          if (t !== undefined) {
            return Curry._3(Stdlib__Format.fprintf(fmt)(/* Format */{
                            _0: {
                              TAG: /* Formatting_gen */18,
                              _0: {
                                TAG: /* Open_box */1,
                                _0: /* Format */{
                                  _0: /* End_of_format */0,
                                  _1: ""
                                }
                              },
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* String_literal */11,
                                  _0: ", but got",
                                  _1: {
                                    TAG: /* Formatting_lit */17,
                                    _0: {
                                      TAG: /* Break */0,
                                      _0: "@ ",
                                      _1: 1,
                                      _2: 0
                                    },
                                    _1: {
                                      TAG: /* Formatting_gen */18,
                                      _0: {
                                        TAG: /* Open_box */1,
                                        _0: /* Format */{
                                          _0: /* End_of_format */0,
                                          _1: ""
                                        }
                                      },
                                      _1: {
                                        TAG: /* Alpha */15,
                                        _0: {
                                          TAG: /* Formatting_lit */17,
                                          _0: /* Close_box */0,
                                          _1: {
                                            TAG: /* Formatting_lit */17,
                                            _0: /* Close_box */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            },
                            _1: "@[%s, but got@ @[%a@]@]"
                          }), msg, pp, Caml_option.valFromOption(t));
          } else {
            return Curry._1(Stdlib__Format.fprintf(fmt)(/* Format */{
                            _0: {
                              TAG: /* Formatting_gen */18,
                              _0: {
                                TAG: /* Open_box */1,
                                _0: /* Format */{
                                  _0: /* End_of_format */0,
                                  _1: ""
                                }
                              },
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* Formatting_lit */17,
                                  _0: /* Close_box */0,
                                  _1: /* End_of_format */0
                                }
                              }
                            },
                            _1: "@[%s@]"
                          }), msg);
          }
      case /* Decoder_errors */1 :
          var errors$1 = errors._0;
          var errors_trunc = Decoders_bs__Decoders_util.My_list.take(5, errors$1);
          var not_shown = Stdlib__List.length(errors$1) - 5 | 0;
          var partial_arg = Stdlib__Format.pp_print_space;
          return Curry._3(Stdlib__Format.fprintf(fmt)(/* Format */{
                          _0: {
                            TAG: /* Formatting_gen */18,
                            _0: {
                              TAG: /* Open_box */1,
                              _0: /* Format */{
                                _0: /* End_of_format */0,
                                _1: ""
                              }
                            },
                            _1: {
                              TAG: /* Alpha */15,
                              _0: {
                                TAG: /* Formatting_lit */17,
                                _0: {
                                  TAG: /* Break */0,
                                  _0: "@ ",
                                  _1: 1,
                                  _2: 0
                                },
                                _1: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: {
                                    TAG: /* Formatting_lit */17,
                                    _0: /* Close_box */0,
                                    _1: /* End_of_format */0
                                  }
                                }
                              }
                            }
                          },
                          _1: "@[%a@ %s@]"
                        }), (function (param, param$1) {
                        return Stdlib__Format.pp_print_list(partial_arg, pp_error, param, param$1);
                      }), errors_trunc, not_shown > 0 ? Curry._1(Stdlib__Printf.sprintf(/* Format */{
                                _0: {
                                  TAG: /* String_literal */11,
                                  _0: "(...",
                                  _1: {
                                    TAG: /* Int */4,
                                    _0: /* Int_d */0,
                                    _1: /* No_padding */0,
                                    _2: /* No_precision */0,
                                    _3: {
                                      TAG: /* String_literal */11,
                                      _0: " errors not shown...)",
                                      _1: /* End_of_format */0
                                    }
                                  }
                                },
                                _1: "(...%d errors not shown...)"
                              }), not_shown) : "");
      case /* Decoder_tag */2 :
          return Curry._3(Stdlib__Format.fprintf(fmt)(/* Format */{
                          _0: {
                            TAG: /* Formatting_gen */18,
                            _0: {
                              TAG: /* Open_box */1,
                              _0: /* Format */{
                                _0: {
                                  TAG: /* String_literal */11,
                                  _0: "<2>",
                                  _1: /* End_of_format */0
                                },
                                _1: "<2>"
                              }
                            },
                            _1: {
                              TAG: /* String */2,
                              _0: /* No_padding */0,
                              _1: {
                                TAG: /* Char_literal */12,
                                _0: /* ':' */58,
                                _1: {
                                  TAG: /* Formatting_lit */17,
                                  _0: {
                                    TAG: /* Break */0,
                                    _0: "@ ",
                                    _1: 1,
                                    _2: 0
                                  },
                                  _1: {
                                    TAG: /* Formatting_gen */18,
                                    _0: {
                                      TAG: /* Open_box */1,
                                      _0: /* Format */{
                                        _0: /* End_of_format */0,
                                        _1: ""
                                      }
                                    },
                                    _1: {
                                      TAG: /* Alpha */15,
                                      _0: {
                                        TAG: /* Formatting_lit */17,
                                        _0: /* Close_box */0,
                                        _1: {
                                          TAG: /* Formatting_lit */17,
                                          _0: /* Close_box */0,
                                          _1: /* End_of_format */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          },
                          _1: "@[<2>%s:@ @[%a@]@]"
                        }), errors._0, pp_error, errors._1);
      
    }
  };
  var string_of_error = function (e) {
    return Curry._2(Stdlib__Format.asprintf(/* Format */{
                    _0: {
                      TAG: /* Formatting_gen */18,
                      _0: {
                        TAG: /* Open_box */1,
                        _0: /* Format */{
                          _0: {
                            TAG: /* String_literal */11,
                            _0: "<2>",
                            _1: /* End_of_format */0
                          },
                          _1: "<2>"
                        }
                      },
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Formatting_lit */17,
                          _0: /* FFlush */2,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: /* Close_box */0,
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    },
                    _1: "@[<2>%a@?@]"
                  }), pp_error, e);
  };
  var merge_errors = function (e1, e2) {
    switch (e1.TAG | 0) {
      case /* Decoder_errors */1 :
          var e1s = e1._0;
          switch (e2.TAG | 0) {
            case /* Decoder_errors */1 :
                return {
                        TAG: /* Decoder_errors */1,
                        _0: Stdlib.$at(e1s, e2._0)
                      };
            case /* Decoder_error */0 :
            case /* Decoder_tag */2 :
                return {
                        TAG: /* Decoder_errors */1,
                        _0: Stdlib.$at(e1s, {
                              hd: e2,
                              tl: /* [] */0
                            })
                      };
            
          }
      case /* Decoder_error */0 :
      case /* Decoder_tag */2 :
          break;
      
    }
    switch (e2.TAG | 0) {
      case /* Decoder_errors */1 :
          return {
                  TAG: /* Decoder_errors */1,
                  _0: Stdlib.$at({
                        hd: e1,
                        tl: /* [] */0
                      }, e2._0)
                };
      case /* Decoder_error */0 :
      case /* Decoder_tag */2 :
          return {
                  TAG: /* Decoder_errors */1,
                  _0: {
                    hd: e1,
                    tl: {
                      hd: e2,
                      tl: /* [] */0
                    }
                  }
                };
      
    }
  };
  var combine_errors = function (results) {
    var _combined = {
      TAG: /* Ok */0,
      _0: /* [] */0
    };
    var _param = results;
    while(true) {
      var param = _param;
      var combined = _combined;
      if (!param) {
        if (combined.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: Stdlib__List.rev(combined._0)
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Stdlib__List.rev(combined._0)
                };
        }
      }
      var result = param.hd;
      var combined$1;
      if (result.TAG === /* Ok */0) {
        combined$1 = combined.TAG === /* Ok */0 ? ({
              TAG: /* Ok */0,
              _0: {
                hd: result._0,
                tl: combined._0
              }
            }) : ({
              TAG: /* Error */1,
              _0: combined._0
            });
      } else {
        var e = result._0;
        combined$1 = combined.TAG === /* Ok */0 ? ({
              TAG: /* Error */1,
              _0: {
                hd: e,
                tl: /* [] */0
              }
            }) : ({
              TAG: /* Error */1,
              _0: {
                hd: e,
                tl: combined._0
              }
            });
      }
      _param = param.tl;
      _combined = combined$1;
      continue ;
    };
  };
  var of_string = function (string) {
    return Decoders_bs__Decoders_util.My_result.map_err((function (msg) {
                  return {
                          TAG: /* Decoder_tag */2,
                          _0: "Json parse error",
                          _1: {
                            TAG: /* Decoder_error */0,
                            _0: msg,
                            _1: undefined
                          }
                        };
                }), Curry._1(Decodeable.of_string, string));
  };
  var of_file = function (file) {
    return Decoders_bs__Decoders_util.My_result.map_err((function (msg) {
                  return {
                          TAG: /* Decoder_tag */2,
                          _0: Curry._1(Stdlib__Printf.sprintf(/* Format */{
                                    _0: {
                                      TAG: /* String_literal */11,
                                      _0: "While reading ",
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: /* End_of_format */0
                                      }
                                    },
                                    _1: "While reading %s"
                                  }), file),
                          _1: {
                            TAG: /* Decoder_error */0,
                            _0: msg,
                            _1: undefined
                          }
                        };
                }), Curry._1(Decodeable.of_file, file));
  };
  var succeed = function (x) {
    return {
            run: (function (param) {
                return {
                        TAG: /* Ok */0,
                        _0: x
                      };
              })
          };
  };
  var fail = function (msg) {
    return {
            run: (function (input) {
                return {
                        TAG: /* Error */1,
                        _0: {
                          TAG: /* Decoder_error */0,
                          _0: msg,
                          _1: Caml_option.some(input)
                        }
                      };
              })
          };
  };
  var fail_with = function (error) {
    return {
            run: (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              })
          };
  };
  var from_result = function (ok) {
    if (ok.TAG === /* Ok */0) {
      var x = ok._0;
      return {
              run: (function (param) {
                  return {
                          TAG: /* Ok */0,
                          _0: x
                        };
                })
            };
    }
    var error = ok._0;
    return {
            run: (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              })
          };
  };
  var value = {
    run: (function (input) {
        return {
                TAG: /* Ok */0,
                _0: input
              };
      })
  };
  var map = function (f, decoder) {
    return {
            run: (function (input) {
                return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$pipe$eq, Curry._1(decoder.run, input), f);
              })
          };
  };
  var apply = function (f, decoder) {
    return {
            run: (function (input) {
                var match = Curry._1(f.run, input);
                var match$1 = Curry._1(decoder.run, input);
                if (match.TAG === /* Ok */0) {
                  if (match$1.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: Curry._1(match._0, match$1._0)
                          };
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: match$1._0
                          };
                  }
                }
                var e1 = match._0;
                if (match$1.TAG === /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: e1
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: merge_errors(e1, match$1._0)
                        };
                }
              })
          };
  };
  var and_then = function (f, decoder) {
    return {
            run: (function (input) {
                return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, Curry._1(decoder.run, input), (function (result) {
                              return Curry._1(Curry._1(f, result).run, input);
                            }));
              })
          };
  };
  var fix = function (f) {
    var p = {
      LAZY_DONE: false,
      VAL: (function () {
          return Curry._1(f, r);
        })
    };
    var r = {
      run: (function (value) {
          return Curry._1(CamlinternalLazy.force(p).run, value);
        })
    };
    return r;
  };
  var $great$pipe$eq = function (x, f) {
    return map(f, x);
  };
  var $great$great$eq = function (x, f) {
    return and_then(f, x);
  };
  var $less$star$great = apply;
  var Infix = {
    $great$pipe$eq: $great$pipe$eq,
    $great$great$eq: $great$great$eq,
    $less$star$great: $less$star$great
  };
  var maybe = function (decoder) {
    return {
            run: (function (input) {
                var result = Curry._1(decoder.run, input);
                if (result.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: Caml_option.some(result._0)
                        };
                } else {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                }
              })
          };
  };
  var nullable = function (decoder) {
    return {
            run: (function (input) {
                var match = Curry._1(Decodeable.get_null, input);
                if (match !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                } else {
                  return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                return {
                                        TAG: /* Decoder_tag */2,
                                        _0: "Expected null or",
                                        _1: param
                                      };
                              }), Decoders_bs__Decoders_util.My_result.map(Decoders_bs__Decoders_util.My_opt.$$return, Curry._1(decoder.run, input)));
                }
              })
          };
  };
  var one_of = function (decoders) {
    var run = function (input) {
      var _errors = /* [] */0;
      var _param = decoders;
      while(true) {
        var param = _param;
        var errors = _errors;
        if (!param) {
          return {
                  TAG: /* Error */1,
                  _0: {
                    TAG: /* Decoder_tag */2,
                    _0: "I tried the following decoders but they all failed",
                    _1: {
                      TAG: /* Decoder_errors */1,
                      _0: errors
                    }
                  }
                };
        }
        var match = param.hd;
        var result = Curry._1(match[1].run, input);
        if (result.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: result._0
                };
        }
        var msg = Curry._1(Stdlib__Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* Caml_string */3,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: " decoder",
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%S decoder"
                }), match[0]);
        _param = param.tl;
        _errors = {
          hd: {
            TAG: /* Decoder_tag */2,
            _0: msg,
            _1: {
              TAG: /* Decoder_errors */1,
              _0: {
                hd: result._0,
                tl: /* [] */0
              }
            }
          },
          tl: errors
        };
        continue ;
      };
    };
    return {
            run: run
          };
  };
  var primitive_decoder = function (get_value, message) {
    return {
            run: (function (t) {
                var value = Curry._1(get_value, t);
                if (value !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: Caml_option.valFromOption(value)
                        };
                } else {
                  return Curry._1(fail(message).run, t);
                }
              })
          };
  };
  var string = primitive_decoder(Decodeable.get_string, "Expected a string");
  var $$int = primitive_decoder(Decodeable.get_int, "Expected an int");
  var $$float = primitive_decoder(Decodeable.get_float, "Expected a float");
  var bool = primitive_decoder(Decodeable.get_bool, "Expected a bool");
  var $$null = function ($$default) {
    return map((function (param) {
                  return $$default;
                }), primitive_decoder(Decodeable.get_null, "Expected a null"));
  };
  var list = function (decoder) {
    return {
            run: (function (t) {
                var values = Curry._1(Decodeable.get_list, t);
                if (values !== undefined) {
                  return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                return {
                                        TAG: /* Decoder_tag */2,
                                        _0: "while decoding a list",
                                        _1: {
                                          TAG: /* Decoder_errors */1,
                                          _0: param
                                        }
                                      };
                              }), combine_errors(Decoders_bs__Decoders_util.My_list.mapi((function (i, x) {
                                        var partial_arg = Curry._1(Stdlib__Printf.sprintf(/* Format */{
                                                  _0: {
                                                    TAG: /* String_literal */11,
                                                    _0: "element ",
                                                    _1: {
                                                      TAG: /* Int */4,
                                                      _0: /* Int_i */3,
                                                      _1: /* No_padding */0,
                                                      _2: /* No_precision */0,
                                                      _3: /* End_of_format */0
                                                    }
                                                  },
                                                  _1: "element %i"
                                                }), i);
                                        return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                                      return {
                                                              TAG: /* Decoder_tag */2,
                                                              _0: partial_arg,
                                                              _1: param
                                                            };
                                                    }), Curry._1(decoder.run, x));
                                      }), values)));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected a list",
                            _1: Caml_option.some(t)
                          }
                        };
                }
              })
          };
  };
  var list_filter = function (decoder) {
    var go = function (i, param) {
      if (!param) {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      }
      var vs = param.tl;
      var partial_arg = Curry._1(Stdlib__Printf.sprintf(/* Format */{
                _0: {
                  TAG: /* String_literal */11,
                  _0: "element ",
                  _1: {
                    TAG: /* Int */4,
                    _0: /* Int_i */3,
                    _1: /* No_padding */0,
                    _2: /* No_precision */0,
                    _3: /* End_of_format */0
                  }
                },
                _1: "element %i"
              }), i);
      return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                        return {
                                TAG: /* Decoder_tag */2,
                                _0: partial_arg,
                                _1: param
                              };
                      }), Curry._1(decoder.run, param.hd)), (function (x) {
                    if (x === undefined) {
                      return go(i + 1 | 0, vs);
                    }
                    var x$1 = Caml_option.valFromOption(x);
                    return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, go(i + 1 | 0, vs), (function (xs) {
                                  return Decoders_bs__Decoders_util.My_result.$$return({
                                              hd: x$1,
                                              tl: xs
                                            });
                                }));
                  }));
    };
    return {
            run: (function (t) {
                var values = Curry._1(Decodeable.get_list, t);
                if (values !== undefined) {
                  return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                return {
                                        TAG: /* Decoder_tag */2,
                                        _0: "while decoding a list",
                                        _1: param
                                      };
                              }), go(0, values));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected a list",
                            _1: Caml_option.some(t)
                          }
                        };
                }
              })
          };
  };
  var list_fold_left = function (decoder_func, init) {
    return {
            run: (function (t) {
                var values = Curry._1(Decodeable.get_list, t);
                if (values !== undefined) {
                  return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                return {
                                        TAG: /* Decoder_tag */2,
                                        _0: "while decoding a list",
                                        _1: param
                                      };
                              }), Decoders_bs__Decoders_util.My_list.fold_left((function (param, el) {
                                      var i = param[1];
                                      return [
                                              Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, param[0], (function (acc) {
                                                      var partial_arg = Curry._1(Stdlib__Printf.sprintf(/* Format */{
                                                                _0: {
                                                                  TAG: /* String_literal */11,
                                                                  _0: "element ",
                                                                  _1: {
                                                                    TAG: /* Int */4,
                                                                    _0: /* Int_i */3,
                                                                    _1: /* No_padding */0,
                                                                    _2: /* No_precision */0,
                                                                    _3: /* End_of_format */0
                                                                  }
                                                                },
                                                                _1: "element %i"
                                                              }), i);
                                                      return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                                                    return {
                                                                            TAG: /* Decoder_tag */2,
                                                                            _0: partial_arg,
                                                                            _1: param
                                                                          };
                                                                  }), Curry._1(Curry._1(decoder_func, acc).run, el));
                                                    })),
                                              i + 1 | 0
                                            ];
                                    }), [
                                    {
                                      TAG: /* Ok */0,
                                      _0: init
                                    },
                                    0
                                  ], values)[0]);
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected a list",
                            _1: Caml_option.some(t)
                          }
                        };
                }
              })
          };
  };
  var field = function (key, value_decoder) {
    return {
            run: (function (t) {
                var value = Decoders_bs__Decoders_util.My_opt.flat_map((function (param) {
                        return Decoders_bs__Decoders_util.My_list.find_map((function (param) {
                                      var s = Curry._1(Decodeable.get_string, param[0]);
                                      if (s !== undefined && s === key) {
                                        return Caml_option.some(param[1]);
                                      }
                                      
                                    }), param);
                      }), Curry._1(Decodeable.get_key_value_pairs, t));
                if (value === undefined) {
                  return Curry._1(fail(Curry._1(Stdlib__Printf.sprintf(/* Format */{
                                          _0: {
                                            TAG: /* String_literal */11,
                                            _0: "Expected an object with an attribute ",
                                            _1: {
                                              TAG: /* Caml_string */3,
                                              _0: /* No_padding */0,
                                              _1: /* End_of_format */0
                                            }
                                          },
                                          _1: "Expected an object with an attribute %S"
                                        }), key)).run, t);
                }
                var partial_arg = Curry._1(Stdlib__Printf.sprintf(/* Format */{
                          _0: {
                            TAG: /* String_literal */11,
                            _0: "in field ",
                            _1: {
                              TAG: /* Caml_string */3,
                              _0: /* No_padding */0,
                              _1: /* End_of_format */0
                            }
                          },
                          _1: "in field %S"
                        }), key);
                return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                              return {
                                      TAG: /* Decoder_tag */2,
                                      _0: partial_arg,
                                      _1: param
                                    };
                            }), Curry._1(value_decoder.run, Caml_option.valFromOption(value)));
              })
          };
  };
  var field_opt = function (key, value_decoder) {
    return {
            run: (function (t) {
                var value = Decoders_bs__Decoders_util.My_opt.flat_map((function (param) {
                        return Decoders_bs__Decoders_util.My_list.find_map((function (param) {
                                      var s = Curry._1(Decodeable.get_string, param[0]);
                                      if (s !== undefined && s === key) {
                                        return Caml_option.some(param[1]);
                                      }
                                      
                                    }), param);
                      }), Curry._1(Decodeable.get_key_value_pairs, t));
                if (value === undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                }
                var partial_arg = Curry._1(Stdlib__Printf.sprintf(/* Format */{
                          _0: {
                            TAG: /* String_literal */11,
                            _0: "in field ",
                            _1: {
                              TAG: /* Caml_string */3,
                              _0: /* No_padding */0,
                              _1: /* End_of_format */0
                            }
                          },
                          _1: "in field %S"
                        }), key);
                return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                              return {
                                      TAG: /* Decoder_tag */2,
                                      _0: partial_arg,
                                      _1: param
                                    };
                            }), Decoders_bs__Decoders_util.My_result.map((function (v) {
                                  return Caml_option.some(v);
                                }), Curry._1(value_decoder.run, Caml_option.valFromOption(value))));
              })
          };
  };
  var single_field = function (value_decoder) {
    return {
            run: (function (t) {
                var match = Curry._1(Decodeable.get_key_value_pairs, t);
                if (match === undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected an object with a single attribute",
                            _1: Caml_option.some(t)
                          }
                        };
                }
                if (!match) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected an object with a single attribute",
                            _1: Caml_option.some(t)
                          }
                        };
                }
                if (match.tl) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected an object with a single attribute",
                            _1: Caml_option.some(t)
                          }
                        };
                }
                var match$1 = match.hd;
                var key = Curry._1(Decodeable.get_string, match$1[0]);
                if (key === undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected an object with a string key",
                            _1: Caml_option.some(t)
                          }
                        };
                }
                var partial_arg = Curry._1(Stdlib__Printf.sprintf(/* Format */{
                          _0: {
                            TAG: /* String_literal */11,
                            _0: "in field ",
                            _1: {
                              TAG: /* Caml_string */3,
                              _0: /* No_padding */0,
                              _1: /* End_of_format */0
                            }
                          },
                          _1: "in field %S"
                        }), key);
                return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                              return {
                                      TAG: /* Decoder_tag */2,
                                      _0: partial_arg,
                                      _1: param
                                    };
                            }), Curry._1(Curry._1(value_decoder, key).run, match$1[1]));
              })
          };
  };
  var index = function (i, decoder) {
    return {
            run: (function (t) {
                var l = Curry._1(Decodeable.get_list, t);
                if (l === undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected a list",
                            _1: Caml_option.some(t)
                          }
                        };
                }
                var item;
                try {
                  item = Caml_option.some(Stdlib__List.nth(l, i));
                }
                catch (raw_exn){
                  var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                  if (exn.RE_EXN_ID === Stdlib.Failure || exn.RE_EXN_ID === Stdlib.Invalid_argument) {
                    item = undefined;
                  } else {
                    throw exn;
                  }
                }
                if (item !== undefined) {
                  return Curry._1(decoder.run, Caml_option.valFromOption(item));
                } else {
                  return Curry._1(fail("expected a list with at least " + (String(i) + " elements")).run, t);
                }
              })
          };
  };
  var uncons = function (tail, head) {
    return {
            run: (function (value) {
                var match = Curry._1(Decodeable.get_list, value);
                if (match === undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected a list",
                            _1: Caml_option.some(value)
                          }
                        };
                }
                if (!match) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected a non-empty list",
                            _1: Caml_option.some(value)
                          }
                        };
                }
                var rest = match.tl;
                return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                  return {
                                          TAG: /* Decoder_tag */2,
                                          _0: "while consuming a list element",
                                          _1: param
                                        };
                                }), Curry._1(head.run, match.hd)), (function (x) {
                              return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                            return {
                                                    TAG: /* Decoder_tag */2,
                                                    _0: "after consuming a list element",
                                                    _1: param
                                                  };
                                          }), Curry._1(Curry._1(tail, x).run, Curry._1(Decodeable.to_list, rest)));
                            }));
              })
          };
  };
  var at = function (path, decoder) {
    if (!path) {
      return {
              run: (function (input) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Must provide at least one key to 'at'",
                            _1: Caml_option.some(input)
                          }
                        };
                })
            };
    }
    var key = path.hd;
    if (path.tl) {
      return field(key, at(path.tl, decoder));
    } else {
      return field(key, decoder);
    }
  };
  var keys$p = function (key_decoder) {
    return {
            run: (function (value) {
                var assoc = Curry._1(Decodeable.get_key_value_pairs, value);
                if (assoc !== undefined) {
                  return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                return {
                                        TAG: /* Decoder_tag */2,
                                        _0: "Failed while decoding the keys of an object",
                                        _1: {
                                          TAG: /* Decoder_errors */1,
                                          _0: param
                                        }
                                      };
                              }), combine_errors(Stdlib__List.map((function (param) {
                                        return Curry._1(key_decoder.run, param[0]);
                                      }), assoc)));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected an object",
                            _1: Caml_option.some(value)
                          }
                        };
                }
              })
          };
  };
  var keys = keys$p(string);
  var key_value_pairs$p = function (key_decoder, value_decoder) {
    return {
            run: (function (value) {
                var assoc = Curry._1(Decodeable.get_key_value_pairs, value);
                if (assoc !== undefined) {
                  return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                return {
                                        TAG: /* Decoder_tag */2,
                                        _0: "Failed while decoding key-value pairs",
                                        _1: {
                                          TAG: /* Decoder_errors */1,
                                          _0: param
                                        }
                                      };
                              }), combine_errors(Stdlib__List.map((function (param) {
                                        var value_val = param[1];
                                        return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, Curry._1(key_decoder.run, param[0]), (function (key) {
                                                      return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$pipe$eq, Curry._1(value_decoder.run, value_val), (function (value) {
                                                                    return [
                                                                            key,
                                                                            value
                                                                          ];
                                                                  }));
                                                    }));
                                      }), assoc)));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected an object",
                            _1: Caml_option.some(value)
                          }
                        };
                }
              })
          };
  };
  var key_value_pairs = function (value_decoder) {
    return key_value_pairs$p(string, value_decoder);
  };
  var key_value_pairs_seq$p = function (key_decoder, value_decoder) {
    return {
            run: (function (value) {
                var assoc = Curry._1(Decodeable.get_key_value_pairs, value);
                if (assoc !== undefined) {
                  return Decoders_bs__Decoders_util.My_result.map_err((function (param) {
                                return {
                                        TAG: /* Decoder_tag */2,
                                        _0: "Failed while decoding key-value pairs",
                                        _1: {
                                          TAG: /* Decoder_errors */1,
                                          _0: param
                                        }
                                      };
                              }), combine_errors(Stdlib__List.map((function (param) {
                                        var value_val = param[1];
                                        return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, Curry._1(key_decoder.run, param[0]), (function (key) {
                                                      return Curry._1(Curry._1(value_decoder, key).run, value_val);
                                                    }));
                                      }), assoc)));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Decoder_error */0,
                            _0: "Expected an object",
                            _1: Caml_option.some(value)
                          }
                        };
                }
              })
          };
  };
  var key_value_pairs_seq = function (value_decoder) {
    return key_value_pairs_seq$p(string, value_decoder);
  };
  var decode_value = function (decoder, input) {
    return Curry._1(decoder.run, input);
  };
  var decode_string = function (decoder, string) {
    return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, of_string(string), (function (param) {
                  return Curry._1(decoder.run, param);
                }));
  };
  var decode_file = function (decoder, file) {
    return Curry._2(Decoders_bs__Decoders_util.My_result.Infix.$great$great$eq, of_file(file), (function (param) {
                  return Curry._1(decoder.run, param);
                }));
  };
  var custom = function (customDecoder, next) {
    return apply(next, customDecoder);
  };
  var required = function (key, decoder, next) {
    return apply(next, field(key, decoder));
  };
  var required_at = function (path, decoder, next) {
    return apply(next, at(path, decoder));
  };
  var optional_decoder = function (path_decoder, val_decoder, $$default) {
    var null_or = function (decoder) {
      return one_of({
                  hd: [
                    "non-null",
                    decoder
                  ],
                  tl: {
                    hd: [
                      "null",
                      $$null($$default)
                    ],
                    tl: /* [] */0
                  }
                });
    };
    var handle_result = function (input) {
      var rawValue = Curry._1(path_decoder.run, input);
      if (rawValue.TAG !== /* Ok */0) {
        return {
                run: (function (param) {
                    return {
                            TAG: /* Ok */0,
                            _0: $$default
                          };
                  })
              };
      }
      var decoder = null_or(val_decoder);
      return from_result(Curry._1(decoder.run, rawValue._0));
    };
    return and_then(handle_result, value);
  };
  var optional = function (key, val_decoder, $$default, next) {
    return apply(next, optional_decoder(field(key, value), val_decoder, $$default));
  };
  var optional_at = function (path, val_decoder, $$default, next) {
    return apply(next, optional_decoder(at(path, value), val_decoder, $$default));
  };
  return {
          pp_error: pp_error,
          string_of_error: string_of_error,
          of_string: of_string,
          of_file: of_file,
          string: string,
          $$int: $$int,
          $$float: $$float,
          bool: bool,
          value: value,
          list: list,
          list_filter: list_filter,
          list_fold_left: list_fold_left,
          index: index,
          uncons: uncons,
          field: field,
          field_opt: field_opt,
          single_field: single_field,
          at: at,
          maybe: maybe,
          nullable: nullable,
          one_of: one_of,
          map: map,
          apply: apply,
          keys: keys,
          key_value_pairs: key_value_pairs,
          key_value_pairs_seq: key_value_pairs_seq,
          keys$p: keys$p,
          key_value_pairs$p: key_value_pairs$p,
          key_value_pairs_seq$p: key_value_pairs_seq$p,
          succeed: succeed,
          fail: fail,
          fail_with: fail_with,
          from_result: from_result,
          and_then: and_then,
          fix: fix,
          Infix: Infix,
          $great$pipe$eq: $great$pipe$eq,
          $great$great$eq: $great$great$eq,
          $less$star$great: $less$star$great,
          decode_value: decode_value,
          decode_string: decode_string,
          decode_file: decode_file,
          Pipeline: {
            decode: succeed,
            required: required,
            required_at: required_at,
            optional: optional,
            optional_at: optional_at,
            custom: custom
          }
        };
}

export {
  Make ,
}
/* Stdlib Not a pure module */
